import _ from 'lodash';
import React, { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { sleep } from './sleep';
import { GetFromServerFn, SaveToServerFn, Store } from './Store';

/** After 5 seconds with no subscribers in the frontend, the store will be removed from the cache */
const STORE_EXPIRE_MS = 5000;

/**
 * A store definition is used to retrieve and create a store
 */
export type StoreDefinition<TValue> = {
  /**
   * The key is used to identify and deduplicate the store. If two useStore calls have the same key
   * they will share the same store.
   * The key uses a deepEqual so you don't have to worry about object references, can pass in arrays etc.
   * The key can be any type, and should contain all the dependencies of the store. For example if the store
   * is storing the result of an API call, it should almost certainly contain the parameters of the API call
   * as well as the route used.
   */
  key: unknown;
  /** Periodically call the getFromServer method */
  autoSync?: boolean;
  /** How long to wait between calls to the getFromServer method. Measured in ms */
  autoSyncPeriodMs?: number;
  /** Function used to fetch data from the server. This is automatically called as needed */
  getFromServer: GetFromServerFn<TValue>;
  /** Function to save data back to the server, automatically called after changes */
  saveToServer?: SaveToServerFn<TValue>;
  /** Setting trackHistory to true allows the undo and redo functions on the store to work, at the cost of extra memory usage */
  trackHistory?: boolean;
};
export interface StoresValue {
  /**
   * Attempt to retrieve a store from the StoreContext, or create it if it doesn't exist
   * @param definition How to find/create the store
   * @returns
   */
  getOrCreateStore: <T>(definition: StoreDefinition<T>) => Store<T>;
}

export const StoresContext = createContext<StoresValue>({
  getOrCreateStore: () => {
    throw new Error('No StoresProvider for remote datasyncing. Have you added one to your app?');
  },
});

const WAIT_BETWEEN_SYNC_MS = 1e3;
const WAIT_AFTER_ERROR_MS = 30e3;

interface AutoSyncer {
  stop: () => void;
}

function autoSyncStore<T>(store: Store<T>, autoSyncPeriod?: number): AutoSyncer {
  let sync = true;

  (async () => {
    while (sync) {
      try {
        await store.download();
        await sleep(autoSyncPeriod || WAIT_BETWEEN_SYNC_MS);
      } catch (e) {
        await sleep(WAIT_AFTER_ERROR_MS);
      }
    }
  })().catch(console.warn);

  return {
    stop: () => {
      sync = false;
    },
  };
}

function storeDefToStore<T>(storeDef: StoreDefinition<T>): {
  store: Store<T>;
  autoSyncer?: AutoSyncer;
} {
  const store = new Store(storeDef, undefined, undefined, storeDef.trackHistory);

  const autoSyncer = storeDef.autoSync
    ? autoSyncStore(store, storeDef.autoSyncPeriodMs)
    : undefined;
  return { store, autoSyncer };
}

/**
 * The stores provide is where stores ... are stored. This provides a centralized place for data so that
 * multiple parts of the app can share results from the same API requests, and that changes to this data
 * is reflected in all parts of the app.
 *
 * This should be added once at the top level of the app
 */
export const StoresProvider: React.FC = ({ children }) => {
  const stores = useRef<{ key: unknown; value: Store<unknown> }[]>([]);

  const remove = useCallback((key: unknown) => {
    stores.current = stores.current.filter((s) => s.key !== key);
  }, []);

  const get = useCallback((key: unknown) => {
    // Use isEqual here so that keys of objects can be used as a key
    return stores.current.find((s) => _.isEqual(s.key, key))?.value;
  }, []);

  const insert = useCallback((key: unknown, value: Store<unknown>) => {
    stores.current.push({ key, value });
  }, []);

  const getOrCreateStore = useCallback(
    (storeDef: StoreDefinition<unknown>) => {
      const { key } = storeDef;
      const existingStore = get(key);
      if (existingStore) {
        return existingStore;
      }
      const { store: newStore, autoSyncer } = storeDefToStore(storeDef);

      newStore.download().catch(console.warn);

      insert(key, newStore);

      // If a store has no subscribers for 5 seconds, remove it.
      const removeFunc = _.debounce(() => {
        if (!newStore.isHot()) {
          remove(key);
          autoSyncer?.stop();
        }
      }, STORE_EXPIRE_MS);
      newStore.onIsHotChanged(removeFunc);

      return newStore;
    },
    [remove, get, insert],
  );

  const value = useMemo(() => ({ getOrCreateStore }), [getOrCreateStore]);

  /**
   * Warn the user before quitting the page if an store is not idle
   */
  const beforeUnload = useCallback((e) => {
    const anyDirty = stores.current.some((s) => s.value.isDirty());
    if (!anyDirty) {
      return undefined;
    }
    const confirmationMessage =
      'Some changes are unsaved. Are you sure you want to leave the page?';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [beforeUnload]);

  return <StoresContext.Provider value={value as StoresValue}>{children}</StoresContext.Provider>;
};
